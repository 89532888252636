import $ from 'jquery';
import AOS from 'aos';
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';

import 'aos/dist/aos.css';
import 'swiper/swiper.min.css';
import { has } from 'lodash';


Swiper.use([Autoplay, Navigation, Pagination]);

AOS.init();
//import 'bootstrap';
$(function(){
    $(window).scroll(function(){
        if($(this).scrollTop() > 0){
            $("header").addClass("active");
        }else{
            $("header").removeClass("active");
        }
    });
    $(".menu-toggler").on('click',() => {
        $("body > .drawer").toggleClass("active");
    });
});

//Swiper.use([Navigation, Pagination]);

new Swiper(".ref-slider", {
    loop:true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        // when window width is >= 480px
        300: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        // when window width is >= 640px
        1024: {
          slidesPerView: 6,
          spaceBetween: 30
        }
      }
});

new Swiper(".services-slider", {
  loop:true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      576: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 640px
      1024: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
});

new Swiper(".sectors-slider", {
  loop:true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      576: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      // when window width is >= 640px
      1024: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
});

new Swiper(".introSwiper",{
 loop:true,
  autoplay: {
   delay: 6000,
   disableOnInteraction: false,
 },
pagination: {
  el: ".swiper-pagination",
  clickable: true,
},
navigation: {
  nextEl: ".swiper-button-next",
  prevEl: ".swiper-button-prev",
},
})
